import { faBug } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStore } from '@nanostores/react';
import type { MetaFunction } from '@remix-run/react';
import { isRouteErrorResponse, Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteError } from '@remix-run/react';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import type { PropsWithChildren } from 'react';

import './root.scss';
import { useMessages } from './hooks/useMessages';
import { $locale, i18n } from './stores/i18n';
import { $settings } from './stores/settings';

const messages = i18n('error', {
  title: 'Uh oh! Something went horribly wrong.'
});

export const meta: MetaFunction = () => [{
  title: 'APK Signer Tool | PPDS'
}];

function Layout({ children }: PropsWithChildren) {
  const { theme } = useStore($settings);
  const locale = useStore($locale);

  return (
    <html lang={locale} className={theme !== 'auto' ? theme : undefined}>
      <head>
        <meta charSet="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <Meta/>
        <Links/>
      </head>
      <body>
        {children}
        <ScrollRestoration/>
        <Scripts/>
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const t = useMessages(messages);
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  return (
    <Layout>
      <main className="error-boundary">
        <FontAwesomeIcon icon={faBug} size="xl"/>
        {t.title}
        <pre className="error">
          {isRouteErrorResponse(error) ? JSON.stringify(error.data, undefined, 2) : error instanceof Error && error.stack}
        </pre>
      </main>
    </Layout>
  );
}

export function HydrateFallback() {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <Meta/>
        <Links/>
      </head>
      <body>
        Loading...
        <ScrollRestoration/>
        <Scripts/>
      </body>
    </html>
  );
}

export default withSentry(() => (
  <Layout>
    <Outlet/>
  </Layout>
));
